<template>
  <div class="pa-2">

    <page-card
        cardTitle="Theme"
        cardTitleIcon="mdi-heart-multiple-outline"
        cardSubtitle="Choose your preferred design and colourway"
    >

      <v-card-text>

        <p>
          Before we go any further, it’s time to choose your theme for the booklet! We have a wide range of designs to choose from to complement your wedding aesthetic.
          Many of the designs also have colourway variants to help you find your perfect match. Your theme is your chosen design and colourway.
        </p>
        <p>To look through the different designs,
          <a class="font-weight-bold text-h5 grey--text" href="https://www.cherrytopweddings.com/collections/wedding-favour-activity-booklets/" target="_blank"> click here</a>.
          You will be taken to the CherryTop Weddings website design page, where you can use the filters to refine your search and help you make your selection.
          When you have decided on your preferred theme simply click on it in the list below.
        </p>

        <p>
          If you wish to change your preferred theme at any point before final submission, that’s absolutely fine, simply return to this section and make your change.
          Any content you have uploaded will transfer over to your new theme choice.
        </p>

        <p>
          Once you have chosen your theme, you will be ready to start building the inside pages of your booklet.
        </p>
      
      </v-card-text>


      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          :disabled="readOnlyMode"
      >

        <v-autocomplete
            v-model="selectedTheme"
            :items="themes"
            :rules="themeRules"
            @change="themesFieldChangeHandler"
            filled
            outlined
            label="Select your theme"
            class="pa-8"
        ></v-autocomplete>

        <v-btn :disabled="!valid" @click="preview">Preview</v-btn>

      </v-form>

    </page-card>
  </div>
</template>


<script>
import {firestore} from "@/mixins/firebase";
import {form} from "@/mixins/form";
import PageCard from "@/components/PageCard";

export default {
  name: 'Theme',
  components: {
    PageCard,
  },
  mixins: [
    firestore,
    form
  ],
  updated() {
    if (this.selectedTheme === null) {
      this.currentPage.complete = false
    }
  },
  data: () => ({
    themes: [
      {
      text:  "Bloom - Green and Gold",
      },
      {
      text:  "Bloom - Grey",
      },
      {
      text:  "Bloom - Orange and Blue",
      },
      {
      text:  "Captivate - Dark",
      },
      {
      text:  "Captivate - Light",
      },
      {
      text: "Diamante - Blue",
      },
      {
        text: "Diamante - Burgundy",
      },
      {
      text:  "Elegance - Beige",
      },
      {
      text:  "Elegance - Blue",
      },
      {
      text:  "Elegance - Yellow",
      },
      {
      text:  "Eternal - Grey",
      },
      {
        text:  "Eternal - Mustard",
      },
      {
      text:  "Eternal - Muted Crimson",
      },
      {
      text:  "Eternal - Turquoise",
      },
      {
        text:  "Flair - Dusty Heather",
      },
      {
      text:  "Flair - Grey",
      },
      {
      text:  "Flair - Neon",
      },
      {
        text:  "Flair - Turquoise",
      },
      {
      text:  "Flourish - Cream",
      },
      {
      text:  "Flourish - Deep Red",
      },
      {
      text:  "Flourish - Green",
      },
      {
      text:  "Flourish - Grey",
      },
      {
        text:  "Infinite - Cinnamon Rose",
      },
      {
      text:  "Infinite - Deep Blue",
      },
      {
        text:  "Infinite - Dusty Blue",
      },
      {
      text:  "Infinite - Grey",
      },
      {
      text:  "Lace - Grey",
      },
      {
      text:  "Lace - Organic",
      },
      {
      text:  "Lace - Sea Green",
      },
      {
      text:  "Meadow - Bright",
      },
      {
        text: "Minimalist - Neutral",
      },
      {
      text:  "Meadow - Soft",
      },
      {
        text: "Woodland Roses - Blue",
      },
      {
        text: "Woodland Roses - Cream",
      },
      {
        text: "Woodland Roses - Pink",
      },
],
  }),
  methods: {
    themesFieldChangeHandler() {
      // Mark this page as complete/incomplete, if a theme has been selected.
      if (this.selectedTheme !== null) {
        this.currentPage.complete = this.currentFormIsValid()
        this.updateAppStateInFirestore()
      }
    }
  },
  computed: {
    selectedTheme: {
      get: function () {
        return this.$store.state.theme
      },
      set(value) {
        this.$store.commit("setTheme", value)
      }
    },
  }
}
</script>
